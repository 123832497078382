
<template>
  <div class="expend-container">
    <tabs-panel ref="tabs"
                class="tabs-panel"
                :tabs="tabs"
                :keepAlive="false"
                :activeLabel.sync="activeLabel" />
  </div>
</template>

<script>
import { TabsPanel } from 'components/bussiness'
import HasSendRecords from './IntegraHasSendRecords'
import WaitSendRecords from './IntegraWaitSendRecords'
export default {
  name: 'IntegraSendRecords',
  components: {
    TabsPanel
  },
  data () {
    return {
      activeLabel: '已发放',
      tabs: [
        {
          label: '已发放',
          component: HasSendRecords
        },
        {
          label: '待发放',
          component: WaitSendRecords
        }
      ],
      
    }
  },
  computed: {
    extraParams () {
      return { regionId: this.$route.query.regionId }
    }
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
.expend-container {
  overflow: scroll;
}
</style>
