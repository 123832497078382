
<template>
  <div class="expend-container">
    <list
      ref="list"
      :tableRowClassName="tableRowClassName"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :extraParams="extraParams"
      :hasExpendColumn="hasExpendColumn"
    >
      <template #searchSlot>
        <v-input
          v-model="searchParams.serialNumber"
          label="待发放流水号"
          placeholder="请输入发放流水号"
          :width="160"
        ></v-input>
        <v-select2
          label="运营主体"
          v-model="searchParams.regionId"
          v-bind="regionParams"
        ></v-select2>
        <!-- <v-select v-model="searchParams.classify" label="积分种类" :options="integraOptions"></v-select>
        <v-select v-model="searchParams.platform" label="回收方" :options="platformOptions"></v-select> -->
        <v-input
          v-model="searchParams.userName"
          label="接收人"
          placeholder="请输入接收人"
        ></v-input>
        <v-input
          v-model="searchParams.mobileNum"
          label="手机号"
          placeholder="请输入手机号"
        ></v-input>
        <v-datepicker
          type="rangedatetimer"
          :maxDate="maxDate"
          defaultTimeType="week"
          :startTime.sync="searchParams.intimeStart"
          :endTime.sync="searchParams.intimeEnd"
          label="发放时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="终止发放"
          type="text"
          @click="stop(scope.row)"
          v-if="scope.row.status == 1"
        ></v-button>
      </template>
      <template #expendSlot="scope">
        <div class="grant-wrapper">
          <div class="grant-title" v-if="scope.row.integralWaitGrantPlan[0].distributingType==1">发放计划（日平均发放）</div>
          <div class="grant-title" v-if="scope.row.integralWaitGrantPlan[0].distributingType==3">发放计划（月平均发放—每月{{scope.row.integralWaitGrantPlan[0].distributingMonth}}号发放）</div>
          <div class="grant-title" v-if="scope.row.integralWaitGrantPlan[0].distributingType==2">发放计划（周平均发放—每周{{scope.row.integralWaitGrantPlan[0].distributingWeek}}发放）</div>
          <div
            class="grant-each"
            v-for="(item, index) in scope.row.integralWaitGrantPlan"
          >
            <div>{{ item.nextGrantTime }}</div>
            <div>+{{ item.intNum }}</div>
          </div>
        </div>
      </template>
    </list>
  </div>
</template>

<script>
import { getIntegralWaitGrantListByPage, stopURL } from "./api";
import { createAlinkVNode } from "common/vdom";
import { integraOptions, platformOptions,grantStatusMap } from "./map";
import { regionParams } from "common/select2Params";
export default {
  name: "IntegraSendRecords",
  data() {
    return {
      hasExpendColumn: true,
      regionParams,
      searchUrl: getIntegralWaitGrantListByPage,
      maxDate: new Date(),
      searchParams: {
        // classify: undefined, // 积分种类
        // platform: undefined, // 发放方
        userName: "", // 接收人
        mobileNum: "", // 手机号码
        intimeStart: "", // 开始时间
        intimeEnd: "", // 结束时间
        type: 0, // type: 0 发放, 1 消耗
        serialNumber: "", // 消耗流水号
        regionId: "",
      },
      userInfo: {
        userName: "",
        phone: "",
      },
      headers: [
        {
          prop: "serialNumber",
          label: "待发放流水号",
        },
        {
          prop: "sourceType",
          label: "积分种类",
          formatter: () => {
            return "租户积分";
          },
        },
        {
          prop: "regionName",
          label: "运营主体",
        },
        {
          prop: "regionName",
          label: "发放方",
        },
        {
          prop: "remark",
          label: "任务名称",
        },
        {
          prop: "intNum",
          label: "待发放积分",
          formatter: (row) => {
            return row.intNum;
          },
        },
        {
          prop: "status",
          label: "发放状态",
          formatter: (row) => {
            return grantStatusMap[row.status];
          },
        },
        {
          prop: "grantEndTime",
          label: "发放结束时间",
        },
        {
          prop: "userName",
          label: "接收人",
          formatter: (row, prop) => {
            return createAlinkVNode(this, row, prop, {
              text: row.userName,
              cb: () => this.recevier(row, prop),
            });
          },
        },
        {
          prop: "mobileNum",
          label: "手机号",
        },
      ],
      integraOptions: integraOptions,
      platformOptions: platformOptions,
    };
  },
  computed: {
    extraParams() {
      return { regionId: this.$route.query.regionId };
    },
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      console.log(row, "123424");
      if (row.status === 1) {
        return "";
      } else {
        return "expend-hide";
      }
    },
    recevier(row, prop) {
      this.userInfo.userName = row.userName
      this.userInfo.phone = row.mobileNum
      this.userInfo.regionName = row.regionName
      this.userInfo.sourceType = '租户积分'
      this.$router.push({
        path: 'personalIntegraDetail',
        query: {
          userInfo: JSON.stringify(this.userInfo),
          id: row.userId,
          regionId: row.regionId,
          from: 'send'
        }
      })
    },
    async stop(row) {
      let isConfirm = await this.$confirm("是否确认终止发放?");
      if (isConfirm) {
        this.$axios.post(stopURL + `?id=${row.id}`).then((res) => {
          if (res.status === 100) {
            this.$refs.list.searchData();
            this.$message({
              type: "success",
              message: "终止发放成功！",
              center: true,
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .expend-hide {
  .el-table__expand-icon {
    display: none;
  }
}
.expend-container {
  overflow: scroll;
  .grant-wrapper {
    padding: 0 10px;
    .grant-title {
      padding-bottom: 5px;
    }
    .grant-each {
      display: flex;
      padding-top: 6px;
      margin-right: 8px !important;
      &.last-of-type {
        padding-right: 8px;
      }
      > div {
        flex: 1;
      }
    }
  }
}
</style>
