
<template>
  <div class="expend-container">
    <list :searchUrl="searchUrl" :hasOperateColumn="false" :searchParams.sync="searchParams" :headers="headers" :extraParams="extraParams">
      <template #searchSlot>
        <v-input v-model="searchParams.serialNumber" label="发放流水号" placeholder="请输入发放流水号" :width="160"></v-input>
        <v-select2 label="运营主体"
                   v-model="searchParams.regionId"
                   v-bind="regionParams"></v-select2>
        <!-- <v-select v-model="searchParams.classify" label="积分种类" :options="integraOptions"></v-select>
        <v-select v-model="searchParams.platform" label="回收方" :options="platformOptions"></v-select> -->
        <v-input v-model="searchParams.userName" label="接收人" placeholder="请输入接收人"></v-input>
        <v-input v-model="searchParams.mobileNum" label="手机号" placeholder="请输入手机号"></v-input>
        <v-datepicker type="rangedatetimer" :maxDate="maxDate" defaultTimeType="week" :startTime.sync="searchParams.intimeStart" :endTime.sync="searchParams.intimeEnd" label="发放时间"/>
      </template>
    </list>
  </div>
</template>

<script>
import { getIntegralListByPage } from './api'
import { createAlinkVNode } from 'common/vdom'
import { integraOptions, platformOptions } from './map'
import { regionParams } from 'common/select2Params'
export default {
  name: 'IntegraSendRecords',
  data () {
    return {
      regionParams,
      searchUrl: getIntegralListByPage,
      maxDate: new Date(),
      searchParams: {
        // classify: undefined, // 积分种类
        // platform: undefined, // 发放方
        userName: '', // 接收人
        mobileNum: '', // 手机号码
        intimeStart: '', // 开始时间
        intimeEnd: '', // 结束时间
        type: 0, // type: 0 发放, 1 消耗
        serialNumber: '', // 消耗流水号
        regionId: ''
      },
      userInfo: {
        userName: '',
        phone: ''
      },
      headers: [
        {
          prop: 'serialNumber',
          label: '发放流水号'
        },
        {
          prop: 'sourceType',
          label: '积分种类',
          formatter: () => {
            return '租户积分'
          }
        },
        {
          prop: 'regionName',
          label: '运营主体'
        },
        {
          prop: 'regionName',
          label: '发放方'
        },
        {
          prop: 'remark',
          label: '任务名称'
        },
        {
          prop: 'intNum',
          label: '积分值',
          formatter: (row) => {
            return '+'+ row.intNum
          }
        },       
        {
          prop: 'userName',
          label: '接收人',
          formatter: (row, prop) => {
            return createAlinkVNode(this, row, prop, {
              text: row.userName,
              cb: () => this.recevier(row, prop)
            })
          }
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'intime',
          label: '发放时间'
        }
      ],
      integraOptions: integraOptions,
      platformOptions: platformOptions
    }
  },
  computed: {
    extraParams () {
      return { regionId: this.$route.query.regionId }
    }
  },
  methods: {
    recevier (row, prop) {
      this.userInfo.userName = row.userName
      this.userInfo.phone = row.mobileNum
      this.userInfo.regionName = row.regionName
      this.userInfo.sourceType = '租户积分'
      this.$router.push({
        path: 'personalIntegraDetail',
        query: {
          userInfo: JSON.stringify(this.userInfo),
          id: row.userId,
          regionId: row.regionId,
          from: 'send'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.expend-container {
  overflow: scroll;
}
</style>
